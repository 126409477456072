<template>
  <section id="age">

    <div class="row">
      <div class="col-12">
        <SectionTabs :tabs="tab_component"/>
      </div>
    </div>

    <div class="row my-3">
      <div class="col-12 section-head d-flex align-items-center">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0"> 
          {{ title_component.subtitle }}
        </h3>
      </div>
    </div>
    
    <div class="row">
      <div class="col-12 col-lg-6 mb-3">
        <div class="row">
          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
            <v-select 
              class="selvue-custom"
              :options="indicadores_list" 
              v-model="indicadores_selected"
              :clearable="false">
            </v-select>
          </div>
          <div class="col">
            <v-select 
              class="selvue-custom"
              :options="sucursales" 
              v-model="selectedSucursal"
              placeholder="Selecciona una sucursal"
              :clearable="true">
            </v-select>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="row">
          <div class="col-12 col-lg-3 col-xl-4 d-flex align-items-center justify-content-lg-end text-secondary">
              Filtrar por periodo:
          </div>
          <div class="col-12 col-lg-9 col-xl-8">

            <div class="row">
              <div class="col-6">
                <InputDate 
                  :max="range.date_end"
                  placeHolder="Inicio"
                  @dateEmit="captureDateStart"
                />
              </div>
              <div class="col-6">
                <InputDate 
                  :min="range.date_start" 
                  placeHolder="Término"
                  @dateEmit="captureDateEnd"
                />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <Loading 
      v-if="loading"
      pixeles="150px"
    />

    <template v-if="!loading">   
      <div class="row mt-2">
        <div class="col-12">
          <h5 class="font-title-bold color-gray">Reconocimientos totales</h5>
          <div class="card card-shadow border-round-10 border-0 mb-3" v-for="(data, i) in rc_sucursal" :key="i">
            <div class="card-body">
              <h5 class="color-gray mb-0 font-title-bold">{{ data.nombre_valor }}</h5>
              <div class="d-flex align-items-center">
                <div class="progress flex-fill me-2 mt-2" style="height:10px;">
                  <div 
                    class="progress-bar" 
                    :style=" 'width: ' + data.percentage + '%;'+'background-color: ' + colores[i] + ';'"
                    aria-valuemin="0" 
                    aria-valuemax="100">
                  </div>
                </div>
                <h5 class="mb-0 font-title-bold">
                  {{ formatPercentage(data.percentage) }}%
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

  </section>
</template>

<script>
  import SectionTabs from "../Section/SectionTabs.vue";
  import { mapActions, mapState } from "vuex";
  import moment from "moment";

  //FF
  import InputDate from "@/components/forms/InputDate.vue"
  import Loading from "@/components/Loading.vue";

  export default {
    components: { 
      SectionTabs,

      //FF
      InputDate,
      Loading
    },
    data() {
      return {
        tab_component: [
          {
            name: "Dashboard",
            route: "recognize-Dashboard",
          },
          {
            name: "Indicadores",
            route: "recognize-age",
          },
        ],
        indicadores_list: [
          { label: "Año", route: "recognize-year" },
          { label: "Gerencia", route: "recognize-management" },
          { label: "Planta", route: "recognize-age" },
          { label: "Top 20", route: "recognize-ranking" },
          { label: "Consultas por colaborador", route: "recognize-contributor" },
          { label: "Detalles de reconocimiento", route: "recognize-reports" },
        ],
        indicadores_selected: "Planta",
        open_indicadores_select_list: false,

        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        title_component: {
          title: "Reconoce",
          subtitle:
            "¡Conoce el impacto de Reconoce! Podrás revisar cifras anuales, por gerencia, top 20, lugar de trabajo y colaborador.",
        },

        rc_sucursal: [],
        colores: ["#357BF3", "#23BE61", "#F26F28", "#EC206B"],

        //FF
        loading: true,
        selectedSucursal: null,
        sucursales: [],
        range: {
          date_start: null,
          date_end: null,
          mask_start: null,
          mask_end: null
        }
      };
    },

    watch: {
      "range.date_start"() {
        this.getReconocimientoPlanta();
      },
      "range.date_end"() {
        this.getReconocimientoPlanta();  
      },
      selectedSucursal() {
        this.getReconocimientoPlanta();
      },
      indicadores_selected(value) {
        this.$router.push({ name: value.route });
      }
    },

    async created() {
      await this.getSucursales();
      await this.getReconocimientoPlanta();
    },
    computed: {
      ...mapState("recognizeModule", ["data_sucursales"]),
    },
    methods: {
      ...mapActions("recognizeModule", ["getReconocimientosPlantaAction", "getSucursalesAction"]),

      async getSucursales() {
        try {
          const resp = await this.getSucursalesAction({ id_empresa: this.id_empresa });
          resp.forEach((e) => {
            this.sucursales.push({id: e.id_sucursal,label: e.nombre_sucursal});
          });
          this.selectedSucursal = this.sucursales[0];
        } catch (error) {
          this.toastGenericError();
          console.error(error);
        }
      },

      async getReconocimientoPlanta() {
        this.loading = true;
        const payload = {
          id_empresa: this.id_empresa,
          fecha_inicio: this.range.date_start !== null ? this.range.mask_start : '',
          fecha_final: this.range.date_end !== null ? this.range.mask_end : moment().endOf('month').format('YYYY-MM-DD'),
          id_planta: this.selectedSucursal !== null ? this.selectedSucursal.id : '0',
        };
        try {
          this.rc_sucursal = await this.getReconocimientosPlantaAction(payload);
          this.dataPercentage(this.rc_sucursal);        
        } catch (error) {
          this.toastGenericError();
          console.error(error);
        }
        this.loading = false;
      },

      dataPercentage(data) {
        var total = 0;
        data.forEach(function (element) {
          total = total + Number(element.cantidad);
        });
        data.forEach(function (element) {
          var el = Math.round((100 * Number(element.cantidad)) / total);
          element.percentage = isNaN(el) ? 0 : el;
        });
      },
      formatPercentage(p) {
        if (p % 1 == 0) {
          return p;
        } else {
          return p.toFixed(1);
        }
      },

      //FF
      captureDateStart(date) {
        this.range.date_start = date;
        this.range.mask_start = moment(date).format('YYYY-MM-DD');
      },
      captureDateEnd(date) {
        this.range.date_end = date;
        this.range.mask_end = moment(date).format('YYYY-MM-DD');
      }
    },
  };
</script>
